import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable, Inject, Directive, Input, HostBinding, ContentChildren, APP_INITIALIZER, isDevMode, NgModule, makeEnvironmentProviders } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { merge, interval } from 'rxjs';
import { startWith } from 'rxjs/operators';
const NGX_PENDO_SETTINGS_TOKEN = new InjectionToken('ngx-pendo-settings', {
  providedIn: 'root',
  factory: () => ({
    pendoApiKey: ''
  })
});
const NGX_PENDO_WINDOW = new InjectionToken('ngx-pendo-window', {
  providedIn: 'root',
  factory: () => {
    const {
      defaultView
    } = inject(DOCUMENT);
    if (!defaultView) {
      throw new Error('Window is not available');
    }
    return defaultView;
  }
});
const NGX_PENDO_CONTEXT = new InjectionToken('ngx-pendo-context', {
  providedIn: 'root',
  factory: () => inject(NGX_PENDO_WINDOW).pendo
});
class NgxPendoService {
  /**
   * Constructor
   *
   * @param settings IPendoSettings
   */
  constructor(settings, pendo) {
    this.pendo = pendo;
    this.pendoIdFormatter = settings.pendoIdFormatter;
  }
  initialize(optionsOrVisitor, account) {
    if ('id' in optionsOrVisitor) {
      this.pendo.initialize({
        visitor: optionsOrVisitor,
        account
      });
    } else {
      this.pendo.initialize(optionsOrVisitor);
    }
  }
  identify(visitor, account) {
    if (typeof visitor === 'string' && (!account || typeof account === 'string')) {
      this.pendo.identify(visitor, account);
    } else {
      this.pendo.identify({
        visitor: visitor,
        account: account
      });
    }
  }
  /**
   * Updates metadata object for the Pendo agent.
   *
   * @param options IPendoOptions
   */
  updateOptions(options) {
    this.pendo.updateOptions(options);
  }
  /**
   * Format Pendo
   *
   * @param ids string[]
   */
  formatPendoId(...ids) {
    return (this.pendoIdFormatter ? ids.map(id => this.pendoIdFormatter(id)) : ids).join('.');
  }
  /**
   * Shuts down the agent and cleans up all timers and listeners.
   */
  teardown() {
    this.pendo.teardown();
  }
  /**
   * Loads Pendo Debugger and extends the global pendo object with additional functionality for debugging purposes.
   */
  enableDebugging() {
    this.pendo.enableDebugging();
  }
  /**
   * Removes Pendo Debugger extension.
   */
  disableDebugging() {
    this.pendo.disableDebugging();
  }
  static {
    this.ɵfac = function NgxPendoService_Factory(t) {
      return new (t || NgxPendoService)(i0.ɵɵinject(NGX_PENDO_SETTINGS_TOKEN), i0.ɵɵinject(NGX_PENDO_CONTEXT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxPendoService,
      factory: NgxPendoService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPendoService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGX_PENDO_SETTINGS_TOKEN]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGX_PENDO_CONTEXT]
    }]
  }], null);
})();
class NgxPendoIdDirective {
  get pendoId() {
    return this.service.formatPendoId(...this._pendoSections, this._pendoId);
  }
  set pendoId(value) {
    this._pendoId = value;
  }
  get parent() {
    return this._parent;
  }
  set parent(value) {
    this._parent = value;
    setTimeout(() => {
      this._pendoSections = [];
      let cur = this.inherit ? value : null;
      while (cur) {
        this._pendoSections.unshift(cur.pendoSection);
        cur = cur.inherit ? cur.parent : null;
      }
    });
  }
  get disableInherit() {
    return this.inherit ? undefined : true;
  }
  constructor(service) {
    this.service = service;
    this._pendoSections = [];
    // eslint-disable-next-line @angular-eslint/no-input-rename
    this.inherit = true;
  }
  static {
    this.ɵfac = function NgxPendoIdDirective_Factory(t) {
      return new (t || NgxPendoIdDirective)(i0.ɵɵdirectiveInject(NgxPendoService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxPendoIdDirective,
      selectors: [["", "ngx-pendo-id", ""]],
      hostVars: 2,
      hostBindings: function NgxPendoIdDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-pendo-id", ctx.pendoId)("ngx-pendo-disable-inherit", ctx.disableInherit);
        }
      },
      inputs: {
        pendoId: [i0.ɵɵInputFlags.None, "ngx-pendo-id", "pendoId"],
        inherit: [i0.ɵɵInputFlags.None, "ngx-pendo-inherit", "inherit"]
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPendoIdDirective, [{
    type: Directive,
    args: [{
      selector: '[ngx-pendo-id]',
      standalone: true
    }]
  }], () => [{
    type: NgxPendoService
  }], {
    pendoId: [{
      type: Input,
      args: ['ngx-pendo-id']
    }, {
      type: HostBinding,
      args: ['attr.data-pendo-id']
    }],
    inherit: [{
      type: Input,
      args: ['ngx-pendo-inherit']
    }],
    disableInherit: [{
      type: HostBinding,
      args: ['attr.ngx-pendo-disable-inherit']
    }]
  });
})();
class NgxPendoSectionDirective {
  constructor() {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    this.inherit = true;
    this.subscriptions = [];
  }
  get disableInherit() {
    return this.inherit ? undefined : true;
  }
  ngAfterContentInit() {
    // set all child parent
    this.subscriptions.push(merge(this.sectionDirectives.changes.pipe(startWith(this.sectionDirectives)), this.idDirectives.changes.pipe(startWith(this.idDirectives))).subscribe(items => items.forEach(item => {
      if (item !== this) {
        item.parent = this;
      }
    })));
  }
  ngOnChanges(changes) {
    if (changes['pendoSection'] && !changes['pendoSection'].firstChange) {
      this.allIdDirectives.forEach(i => i.parent = i.parent);
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }
  static {
    this.ɵfac = function NgxPendoSectionDirective_Factory(t) {
      return new (t || NgxPendoSectionDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxPendoSectionDirective,
      selectors: [["", "ngx-pendo-section", ""]],
      contentQueries: function NgxPendoSectionDirective_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, NgxPendoIdDirective, 4);
          i0.ɵɵcontentQuery(dirIndex, NgxPendoSectionDirective, 4);
          i0.ɵɵcontentQuery(dirIndex, NgxPendoIdDirective, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.idDirectives = _t);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sectionDirectives = _t);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.allIdDirectives = _t);
        }
      },
      hostVars: 2,
      hostBindings: function NgxPendoSectionDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("ngx-pendo-section", ctx.pendoSection)("ngx-pendo-disable-inherit", ctx.disableInherit);
        }
      },
      inputs: {
        pendoSection: [i0.ɵɵInputFlags.None, "ngx-pendo-section", "pendoSection"],
        inherit: [i0.ɵɵInputFlags.None, "ngx-pendo-inherit", "inherit"]
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPendoSectionDirective, [{
    type: Directive,
    args: [{
      selector: '[ngx-pendo-section]',
      standalone: true
    }]
  }], null, {
    pendoSection: [{
      type: Input,
      args: ['ngx-pendo-section']
    }, {
      type: HostBinding,
      args: ['attr.ngx-pendo-section']
    }],
    inherit: [{
      type: Input,
      args: ['ngx-pendo-inherit']
    }],
    disableInherit: [{
      type: HostBinding,
      args: ['attr.ngx-pendo-disable-inherit']
    }],
    idDirectives: [{
      type: ContentChildren,
      args: [NgxPendoIdDirective, {
        descendants: false
      }]
    }],
    sectionDirectives: [{
      type: ContentChildren,
      args: [NgxPendoSectionDirective, {
        descendants: false
      }]
    }],
    allIdDirectives: [{
      type: ContentChildren,
      args: [NgxPendoIdDirective, {
        descendants: true
      }]
    }]
  });
})();
const DEFAULT_PENDO_SCRIPT_ORIGIN = 'https://cdn.pendo.io';
const NGX_PENDO_INITIALIZER_PROVIDER = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: pendoInitializer,
  deps: [NGX_PENDO_SETTINGS_TOKEN, NGX_PENDO_WINDOW]
};
function pendoInitializer($settings, window) {
  return async () => {
    const {
      pendoApiKey,
      pendoScriptOrigin
    } = $settings;
    if (!pendoApiKey) {
      if (isDevMode()) {
        console.error('Empty api key for Pendo. Make sure to provide one when initializing NgxPendoModule.');
      }
      return;
    }
    await new Promise(resolve => {
      const script = document.createElement('script');
      script.async = true;
      script.src = `${pendoScriptOrigin || DEFAULT_PENDO_SCRIPT_ORIGIN}/agent/static/${pendoApiKey}/pendo.js`;
      document.head.appendChild(script);
      script.onerror = async () => {
        // The script may have been blocked by an ad blocker
        console.error('The pendo script may have been blocked,');
        resolve();
      };
      script.onload = async () => {
        // when enableDebugging should load extra js
        const sub = interval(100).subscribe(() => {
          if (window.pendo) {
            sub.unsubscribe();
            resolve();
          }
        });
      };
    });
  };
}
class NgxPendoModule {
  static forRoot(settings) {
    return {
      ngModule: NgxPendoModule,
      providers: [{
        provide: NGX_PENDO_SETTINGS_TOKEN,
        useValue: settings
      }, NGX_PENDO_INITIALIZER_PROVIDER]
    };
  }
  static forChild() {
    return {
      ngModule: NgxPendoModule
    };
  }
  static {
    this.ɵfac = function NgxPendoModule_Factory(t) {
      return new (t || NgxPendoModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxPendoModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPendoModule, [{
    type: NgModule,
    args: [{
      imports: [NgxPendoIdDirective, NgxPendoSectionDirective],
      exports: [NgxPendoIdDirective, NgxPendoSectionDirective]
    }]
  }], null, null);
})();
function provideNgxPendo(settings) {
  return makeEnvironmentProviders([{
    provide: NGX_PENDO_SETTINGS_TOKEN,
    useValue: settings
  }, NGX_PENDO_INITIALIZER_PROVIDER]);
}

/*
 * Public API Surface of ngx-pendo
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NGX_PENDO_CONTEXT, NGX_PENDO_INITIALIZER_PROVIDER, NGX_PENDO_SETTINGS_TOKEN, NGX_PENDO_WINDOW, NgxPendoIdDirective, NgxPendoModule, NgxPendoSectionDirective, NgxPendoService, pendoInitializer, provideNgxPendo };
