import { API_TAG, Environment } from './environment.type'

export const environment: Environment = {
  production: false,
  httpInterceptorList: [`https://api-dev.navix.io${API_TAG}*`],
  env: 'Dev',
  assets: 'https://fpasadevue01sa.blob.core.windows.net/' + 'assets',
  auth: {
    domain: 'auth-dev.navix.io',
    audience: 'https://navix/api',
    clientId: 'pcFnIXBWh6OIBZ3DhMEUZl6E7wNj2MrA',
    apiUri: 'https://fpa-authapi-dev-ue-01-as.azurewebsites.net',
    appUri: 'https://fpa-authapi-dev-ue-01-as.azurewebsites.net',
    errorPath: '/error'
  },
  apis: {
    identity: 'https://api-dev.navix.io',
    management: `https://api-dev.navix.io${API_TAG}`,
  },
  apiKey: {
    management: 'b39fd824bf164e1ca3d19aef1cbe12fd'
  },
  loginUrl: 'https://dev.navix.io' + '/login',
  redirectUrl: 'https://dev.navix.io' + '/dashboard',
  pendo: {
    key: 'c883b44e-1006-4016-5805-b6364c43586b'
  },
  appInsights: {
    connectionString: 'InstrumentationKey=3014c63d-9d2b-4f61-af31-af5ba398e9be;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/;ApplicationId=c699afc4-0041-4c80-b424-f7ddf50ba4f1'
  },
  launchDarkly: {
    clientSideId: '61f9471b6e987d174e324066'
  },
  pspdfkit: {
    licenseKey: 'sTwsuY3iFXvS61qa0eoK7WEqkF9c1_rDUJ3I2viv_G6W1_dpkNCgS8Mb37pVDL5w3t4353FEAU1qM58O1fGv1NgIBaJwlXjX0GIFwMeIY2AG6ffvCcuuJb8ZIqS5WhWN5o9JmDdoait8oATdb3f4Lve3w1JgjnWGnWT2VnaROMulKrYo4tXdFTqCMFJOLM39Dtn9KVRqrReTf499Snbj4EYH0PFdPKZWvr0LST8IPOY8X1Epn4UG2OukOoYWA5aynTNMaMJcJGj1L1M1lk_aJ_MWWoJMrqi8pys5NHCZ5tm0ld-nCpoyt8RgVul-gbiZ8A3sZbU8PFvFhHIrj9Wyly2Rj_N4-wRIoB4ys702q0ZNAU5pKmnEJUxklAAC308AzkKyqW6vSUfYoU_rnBB4-tKlX_Wr_w5ovXuJCW6epcdoduIWZHAMwRa-JD9dm4I2ThxJaKGk8PNEM2DfThgswhWZM5snXz07ciIS8IafYXVutdww_B7RDO4oMnfUEm5oOAUI-61G_08uv2wZP1NRJ1PZEnT2oNzDoBhlNf4Ul99w80OxCcrMpl96yzMpnl-gKGLBoA4Qmgh9aUc-f67tzQ=='
  }
}
