import { environment as base } from './environment.tokenized'
import { API_TAG, Environment } from './environment.type'

export const environment: Environment = {
  ...base,
  production: false,
  httpInterceptorList: [
    ...base.httpInterceptorList,
    `${'https://localhost:5001'}${API_TAG}*`
  ]
}
